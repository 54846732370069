<template>
  <Base title="用户列表" titleIcon="el-icon-s-claim">
    <div>
      <div class="search-row">
        <el-space>
          <el-radio-group
            v-model="filter.isApproved"
            class="inputMargin10"
            @change="query({ pageNum: 1 })"
          >
            <el-radio-button :label="true">已审核</el-radio-button>
            <el-radio-button :label="false">待审核</el-radio-button>
          </el-radio-group>
          <template v-if="user.inRoles(roles.prov)">
            <el-switch
              v-model="filter.isFundCountry"
              active-text="直管县"
              inactive-text="市县"
              @change="
                filter.city = filter.town = null;
                query({ pageNum: 1 });
              "
            />

            <el-select
              v-model="filter.town"
              placeholder="直管县"
              clearable
              v-if="filter.isFundCountry"
            >
              <el-option
                :value="item.id"
                :label="item.name"
                :key="item.id"
                v-for="item in fundCountries"
              ></el-option>
            </el-select>

            <el-select
              v-model="filter.city"
              placeholder="市级"
              clearable
              v-if="!filter.isFundCountry"
            >
              <el-option
                v-for="item in dicCity"
                :key="item.id"
                :value="item.id"
                :label="item.name"
              ></el-option>
            </el-select>
            <el-select
              v-model="filter.town"
              placeholder="区县"
              clearable
              v-if="!filter.isFundCountry"
            >
              <el-option
                v-for="item in dicTown"
                :key="item.id"
                :value="item.id"
                :label="item.name"
              ></el-option>
            </el-select>

            <el-select
              v-model="filter.userType_Id"
              placeholder="用户类型"
              clearable
            >
              <el-option
                v-for="item in userTypeDic"
                :key="item.id"
                :value="item.id"
                :label="item.name"
              ></el-option>
            </el-select>
          </template>
          <el-input
            placeholder="登录名/用户名称/手机"
            v-model="filter.keyword"
            style="width: 500px"
          >
            <template #prepend> 关键字 </template>
          </el-input>
          <el-button
            type="primary"
            style="margin-left: 5px"
            @click="query({ pageNum: 1 })"
            >查询</el-button
          >
          <el-button
            type="primary"
            icon="el-icon-plus"
            style="margin-left: 5px"
            @click="edit(null)"
            >新建用户</el-button
          >
        </el-space>
      </div>
    </div>
    <Manage :setting="manageSetting" @change="query({ pageNum: 1 })" v-if="manageSetting.visible"></Manage>
    <el-table :data="pagerData.list">
      <el-table-column label="地区" prop="areaName"></el-table-column>
      <el-table-column label="名称" prop="name"></el-table-column>
      <el-table-column label="登录账号" prop="loginAccount"></el-table-column>
      <el-table-column label="用户类型" prop="userTypeName"> </el-table-column>
      <el-table-column label="手机号" prop="cellPhone"></el-table-column>
      <!-- <el-table-column label="上次登录" prop="lastLoginTime"></el-table-column> -->
      <el-table-column label="账户锁定">
        <template #default="{ row: item }">
          {{ !item.isLocked ? "否" : "是" }}
        </template>
      </el-table-column>
      <el-table-column label="审核状态" prop="isApproved">
        <template #default="{ row: item }">
          {{ !item.isApproved ? "待审核" : "已审核" }}
        </template>
      </el-table-column>
      <!-- <el-table-column label="备注"></el-table-column> -->
      <el-table-column label="操作" width="260">
        <template #default="{ row: item }">
          <reset-password :id="item.id" v-if="item.isApproved"></reset-password>
          <el-button
            type="primary"
            :icon="item.isApproved ? 'el-icon-edit' : 'el-icon-check'"
            @click="edit(item.id)"
            size="mini"
          ></el-button>

          <el-button
            :loading="isProcessing"
            :type="item.isLocked ? 'danger' : 'primary'"
            :icon="item.isLocked ? 'el-icon-lock' : 'el-icon-unlock'"
            @click="toggleLock(item.id).then(() => query())"
            size="mini"
          ></el-button>
          <el-popconfirm
            title="确认删除吗！？"
            @confirm="remove(item.id).then(() => query())"
            placement="top"
          >
            <template #reference>
              <el-button
                type="primary"
                icon="el-icon-delete"
                size="mini"
                :loading="isProcessing"
              >
              </el-button>
            </template>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      style="padding-left: 5px"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
      v-model:page-size="filter.pageSize"
      :total="pagerData.count"
      layout="sizes,total, prev, pager, next"
      v-model:currentPage="filter.pageNum"
    ></el-pagination>
  </Base>
</template>
<script lang="ts">
import roles from "@/types/roles.ts";
import ResetPassword from "./resetPassword.vue";
import Manage from "./manage.vue";
import { useCityRelTown, usefundCountriesDic } from "@/network/chinaArea";
import {
  defineComponent,
  inject,
  ref,
  onMounted,
  reactive,
  toRefs,
  toRef,
} from "vue";
import { useQuery, useUser, userTypeDic } from "@/network/user.ts";
import Base from "@/views/layout/Base.vue";
export default defineComponent({
  components: { Base, Manage, ResetPassword },
  setup() {
    const user: any = inject("user");
    const filter = reactive({
      pageNum: 1,
      pageSize: 20,
      city: null,
      town: null,
      keyword: null,
      isApproved: true,
      userType_Id: null,
      isFundCountry: false,
      igLocked: true,
    });



    const manageSetting = reactive({
      id: null,
      visible: false,
    });

    const [dicCity, dicTown] = useCityRelTown(
      toRef(filter, "city"),
      toRef(filter, "town"),
      null
    );
    const { data, query } = useQuery(filter);

    const [isProcessing, , , , , toggleLock, remove] = useUser(null);
    const edit = (id) => {
      manageSetting.id = id;
      manageSetting.visible = true;
    };
    // const doQuery = () => {
    //   filter.pageNum = 1;
    //   query();
    // };
    return {
      user,
      roles,
      isProcessing,
      toggleLock,
      remove,
      edit,
      manageSetting,
      userTypeDic: userTypeDic(),
      dicCity,
      dicTown,
      fundCountries: usefundCountriesDic(),
      pagerData: data,
      query,
      filter,
      handleCurrentChange: (e) => {
        filter.pageNum = e;
        query();
      },
      handleSizeChange: (e) => {
        filter.pageSize = e;
        query();
      },
    };
  },
});
</script>
<style lang="scss" scoped></style>
