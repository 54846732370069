<template>
  <el-popconfirm title="确认重置密码！？" @confirm="reset()" placement="top">
    <template #reference>
      <el-button
        type="primary"
        icon="el-icon-refresh"
        size="mini"
        :loading="isLoading"
      ></el-button>
    </template>
  </el-popconfirm>
</template>

<script lang="ts">
import { defineComponent, toRefs } from "vue";
import { ElMessage } from "element-plus";
import { useUser } from "@/network/user.ts";
export default defineComponent({
  props: ["id"],
  emits: ["reset"],
  setup(props, { emit }) {
    const { id } = toRefs(props);
    const [isLoading, , , , resetPassword] = useUser(id);
    const reset = () => {
      if (id.value) {
        resetPassword(id.value).then(() => {
          emit("reset", id.value);
        });
      }
    };
    return { isLoading, reset };
  },
});
</script>

<style scoped>
</style>