
import roles from "@/types/roles.ts";
import ResetPassword from "./resetPassword.vue";
import Manage from "./manage.vue";
import { useCityRelTown, usefundCountriesDic } from "@/network/chinaArea";
import {
  defineComponent,
  inject,
  ref,
  onMounted,
  reactive,
  toRefs,
  toRef,
} from "vue";
import { useQuery, useUser, userTypeDic } from "@/network/user.ts";
import Base from "@/views/layout/Base.vue";
export default defineComponent({
  components: { Base, Manage, ResetPassword },
  setup() {
    const user: any = inject("user");
    const filter = reactive({
      pageNum: 1,
      pageSize: 20,
      city: null,
      town: null,
      keyword: null,
      isApproved: true,
      userType_Id: null,
      isFundCountry: false,
      igLocked: true,
    });



    const manageSetting = reactive({
      id: null,
      visible: false,
    });

    const [dicCity, dicTown] = useCityRelTown(
      toRef(filter, "city"),
      toRef(filter, "town"),
      null
    );
    const { data, query } = useQuery(filter);

    const [isProcessing, , , , , toggleLock, remove] = useUser(null);
    const edit = (id) => {
      manageSetting.id = id;
      manageSetting.visible = true;
    };
    // const doQuery = () => {
    //   filter.pageNum = 1;
    //   query();
    // };
    return {
      user,
      roles,
      isProcessing,
      toggleLock,
      remove,
      edit,
      manageSetting,
      userTypeDic: userTypeDic(),
      dicCity,
      dicTown,
      fundCountries: usefundCountriesDic(),
      pagerData: data,
      query,
      filter,
      handleCurrentChange: (e) => {
        filter.pageNum = e;
        query();
      },
      handleSizeChange: (e) => {
        filter.pageSize = e;
        query();
      },
    };
  },
});
