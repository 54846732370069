
import { defineComponent, toRefs } from "vue";
import { ElMessage } from "element-plus";
import { useUser } from "@/network/user.ts";
export default defineComponent({
  props: ["id"],
  emits: ["reset"],
  setup(props, { emit }) {
    const { id } = toRefs(props);
    const [isLoading, , , , resetPassword] = useUser(id);
    const reset = () => {
      if (id.value) {
        resetPassword(id.value).then(() => {
          emit("reset", id.value);
        });
      }
    };
    return { isLoading, reset };
  },
});
