<template>
  <el-dialog v-model="visible" title="用户管理">
    <el-card v-loading="isLoading">
      <div class="borderForm">
        <el-form :model="form" label-width="200px" ref="refForm" :rules="rules">
          <el-form-item label="名称" prop="name">
            <el-input v-model="form.name" placeholder="名称"></el-input>
          </el-form-item>

          <el-form-item label="用户类型" prop="userType_Id">
            <el-select v-model="form.userType_Id" placeholder="请选择">
              <el-option v-show="user.inRoles(roles.prov) || item.id == 7 || item.id == 9
                " v-for="item in userTypeDic" :key="item.id" :value="item.id" :label="item.name"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="登录账号" prop="loginAccount">
            <el-input v-model="form.loginAccount" placeholder="登录账号"></el-input>
          </el-form-item>
          <el-form-item label="手机号" prop="cellPhone">
            <el-input v-model="form.cellPhone" placeholder="手机号"></el-input>
          </el-form-item>
          <!-- <el-form-item label="登录密码" prop="passwordHash">
            <reset-password :id="form.id"></reset-password>
          </el-form-item> -->
          <el-form-item label="市级" prop="city_ID" v-show="[1, 2, 3, 4, 5, 6, 10, 11].indexOf(form.userType_Id) == -1">
            <el-select v-model="form.city_ID" placeholder="请选择" :disabled="!user.inRoles(roles.prov)">
              <el-option :value="22" label="省直属（默认）" v-if="form.userType_Id == 8 || form.userType_Id == 14"></el-option>
              <el-option v-for="item in dicCity.filter((p) =>
                form.userType_Id == 8 || form.userType_Id == 14
                  ? p.extend?.isBelongProv
                  : !p.extend?.isBelongProv
              )" :key="item.id" :value="item.id" :label="item.name"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="区县" prop="county_ID" v-show="[7, 9].indexOf(form.userType_Id) > -1">
            <el-select v-model="form.county_ID" placeholder="请选择">
              <el-option v-for="item in dicTown?.sort((a, b) => { return b.id - a.id })" :key="item.id" :value="item.id"
                :label="item.name"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="排序" prop="sort">
            <el-input v-model="form.sort" placeholder="排序"></el-input>
          </el-form-item>
          <el-form-item label="账户状态">
            {{ form.isLocked ? "已锁定" : "正常" }}
          </el-form-item>
          <el-form-item label="上次登录时间">
            {{ form.lastLoginTime }}
          </el-form-item>
        </el-form>
      </div>
    </el-card>

    <template #footer>
      <span class="dialog-footer">
        <el-button @click="visible = false">关闭</el-button>
        <el-popconfirm title="确认审核通过吗！？" @confirm="doSave(true)" placement="top" v-if="!form.isApproved">
          <template #reference>
            <el-button type="success" :loading="isLoading">
              {{ isLoading ? "处理中" : "审核通过" }}
            </el-button>
          </template>
        </el-popconfirm>
        <el-button v-else type="primary" icon="el-icon-check" @click="doSave(null)">保存</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script lang="ts">
import roles from "@/types/roles.ts";
import { useCityRelTown } from "@/network/chinaArea";

import { userEditFormRules } from "@/model/userFormRules.ts";
import { useUser, userTypeDic } from "@/network/user";
import {
  inject,
  defineComponent,
  toRefs,
  ref,
  reactive,
  watch,
  Ref,
  PropType,
  computed,
  toRef,
  onMounted,
  nextTick
} from "vue";
import settings from "@/settings";
export default defineComponent({
  props: {
    setting: { type: Object },
  },
  emits: ["change"],
  setup(props, { emit }) {
    const user: any = inject("user");
    const { visible, id } = toRefs(props.setting as any);
    const [isLoading, form, find, save] = useUser(id);
    const [dicCity, dicTown] = useCityRelTown(
      toRef(form, "city_ID"),
      toRef(form, "county_ID"),
      null
    );

    const refForm = ref(null);

    // watch(visible, (v) => {
    //   if (v) {
    //     find().then((p) => {
    //       if (!id.value) {
    //         if (!user.inRoles(roles.prov)) {
    //           form.city_ID = user.city_ID;
    //           form.userType_Id = 7;
    //         }
    //       }
    //       nextTick(() => {
    //         setTimeout(() => refForm.value.clearValidate(), 1);
    //       })

    //     });
    //   }
    // });

    onMounted(() => {
      find().then((p) => {
        if (!id.value) {
          if (!user.inRoles(roles.prov)) {
            form.city_ID = user.city_ID;
            form.userType_Id = 7;
          }
        }
        nextTick(() => {
          setTimeout(() => refForm.value.clearValidate(), 1);
        })

      });
    })
    const doSave = (isApproved) => {
      return new Promise((resolve, reject) => {
        refForm.value.validate((valid) => {
          if (valid) {
            save({ params: { isApproved } }).then(() => {
              resolve("success");
              visible.value = false;
              emit("change", form);
            });
          } else {
            reject("error");
          }
        });
      });
    };

    return {
      user,
      roles,
      userTypeDic: userTypeDic(),
      refForm,
      isLoading,
      visible,
      form,
      doSave,
      rules: userEditFormRules(form),
      dicCity,
      dicTown,
    };
  },
});
</script>

<style scoped></style>
